import Raven from "raven-js";

// Install Raven in production envs
if (process.env.NODE_ENV === "production") {
  Raven.config(DJ_CONST.RAVEN_PUBLIC_DSN).install(); // eslint-disable-line
    // handle rejected promises
    window.addEventListener("unhandledrejection", (evt) => {
        Raven.captureException(evt.reason);
    });
    // If we have authenticated user, pass its data on to Raven
    if (DJ_CONST.user) {
        Raven.setUserContext({
            id: DJ_CONST.user.id,
            email: DJ_CONST.user.email,
            name: DJ_CONST.user.name,
        });
    }
}

const resizeProductionPhases = () => {
    $(".phase-bubble").each((x, bubble) => {
        $(bubble).css("height", $(bubble).css("width"));
    });
};

$(document).ready(() => {
    resizeProductionPhases();
    $(".language-select").on("click", "a", (event) => {
        const parrent = event.target.parentElement;
        const $form = $(parrent).parents("form.language-select");
        $form.find("input[name='language']").val($(parrent).data("language"));
        $form.submit();

        return false;
    });

    $(".mobile-menu .main-menu").on("click", ".dropdown-toggle", (e) => {
        $(e.target.parentElement).find(".mean-expand").click();
    });

    $("#categories .main-cat .show-all").on("click", (e) => {
        e.stopPropagation();
    });

    $(".toggle-category-menu").on("click", () => {
        const $el = $(".row-category-menu");
        $el.toggleClass("with-menu");

        return false;
    });
});

$(window).resize(() => {
    resizeProductionPhases();
});
